import type { UserVariant, ResExposure } from '~/types/amplitude/types'

type ExposureKeys =
  | 'pre-reservation-nouveau-wording-cta'
  | 'multi-inquiry-from-search'

export const useExperimentExposure = () => {
  const ampCookie = useCookie('_amp')
  const { authUserIdTracking } = useAuth()
  const userVariant = useState<UserVariant>('user-variant')

  if (!userVariant.value && process.env.NODE_ENV === 'development') {
    console.error('No user variant found')
  }

  const sendExposure = (flagKey: ExposureKeys) => {
    if (flagKey in userVariant.value) {
      useFetch<ResExposure>('/api/expose', {
        query: {
          flagKey,
          userId: authUserIdTracking.value,
          deviceId: ampCookie.value,
          variant: userVariant.value[flagKey],
        },
      })
    }
  }

  return {
    sendExposure,
  }
}
